.SearchDetails {
    $block: &;
    --form-width: 100%;

    @include vw-xlarge-down {
        --form-width: 800px;
    }

    @include vw-medium-down {
        --form-width: 645px;
    }

    @include vw-search-details-xsmall-down {
        --form-width: 350px;
    }

    &__title {
        @extend %heading-medium;

        margin-bottom: 24px;
        color: white;
        text-align: center;
    }

    &__titleBrands {
        @extend %heading-xsmall;

        max-width: var(--form-width);
        margin-x: auto;
        color: $color-yellow--dark;
        font-weight: $font-weight-medium;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__tabs {
        width: 100%;
    }

    &__controls {
        display: flex;
        width: var(--form-width);
        max-width: 100%;
        margin-top: 40px;

        @include vw-medium-down {
            margin-top: 32px;
        }

        @include vw-search-details-xsmall-down {
            margin-top: 24px;
        }

        &--marginTopSmall {
            margin-top: 16px;
        }
    }

    &__fieldsGrid {
        width: calc(100% + #{$form-small-padding-horizontal * 2});
        display: grid;
        grid-template-columns: 2.5fr 2.2fr 2.5fr 2fr 96px auto;

        @include vw-xlarge-down {
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 12px;
        }

        @include vw-medium-down {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 8px;
        }

        @include vw-search-details-xsmall-down {
            grid-template-columns: 1fr;
        }

        &--fieldWithButton {
            max-width: 700px;
            grid-template-columns: 1fr auto;
            grid-column-gap: 0;

            @include vw-medium-up {
                margin-x: auto;
            }

            @include vw-xsmall-down {
                grid-template-columns: 1fr;
            }
        }
    }

    &__find {
        width: 100%;

        &:disabled {
            color: var(--form-disabled-text-color);
            background-color: var(--form-disabled-background);
            border-color: var(--form-disabled-border);
        }
    }

    &__helpText {
        display: block;
        width: fit-content;
        color: rgba(white, 0.5);
        font-weight: $font-weight-medium;
        font-size: toRem(14px);
        margin-top: 16px;
        margin-left: 3px;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: $color-yellow--dark;
            text-decoration: underline;
        }
    }
}

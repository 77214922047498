.FeaturedSection {
    display: flex;
    align-items: center;
    position: relative;

    &__content {
        @include vw-large-up {
            max-width: 630px;
        }
    }

    &__title {
        @extend %heading-xlarge;

        color: $color-gray--darkest;
        line-height: 1.2;
    }

    &__text {
        color: $color-gray;
        font-size: toRem(22px);
        line-height: 1.6;
        margin-top: 24px;

        @include vw-small-down {
            margin-top: 18px;
            font-size: toRem(18px);
        }
    }

    &__picture {
        position: absolute;
        right: 0;
        top: -60px;
        z-index: -1;

        @include vw-xlarge-down {
            opacity: 0.3;
            right: -60px;
        }

        @include vw-large-down {
            top: -35px;
        }

        @include vw-small-down {
            opacity: 0.2;
            right: -40px;
        }
    }
}

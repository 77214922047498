.SalesAgentSection {
    max-width: 620px;
    width: 100%;

    @include vw-xlarge-down {
        max-width: 640px;
    }

    @include vw-small-down {
        max-width: 360px;
    }

    &__title {
        @extend %heading-xlarge;
    }

    &__description {
        margin-top: 24px;
        font-size: toRem(22px);
        line-height: 1.6;
        color: $color-gray;

        @include vw-medium-down {
            margin-top: 16px;
        }

        @include vw-small-down {
            margin-top: 12px;
            font-size: toRem(18px);
        }
    }

    &__form {
        grid-area: button;
        margin-top: 32px;

        @include vw-medium-down {
            margin-top: 24px;
        }

        @include vw-small-down {
            margin-top: 16px;
        }
    }

    &__picture {
        position: absolute;
        top: 136px;
        right: 0;
        z-index: -1;

        @include vw-xlarge-down {
            top: 140px;
            right: -190px;
        }

        @include vw-medium-down {
            top: 100px;
            right: -220px;
            opacity: 0.25;
        }

        @include vw-small-down {
            top: 20px;
            opacity: 0.15;
            right: -40px;
        }
    }
}

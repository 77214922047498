// Pagination
$swiper-bullet-inner-size: 12px;
$swiper-bullet-inner-size-s: 12px;
$swiper-bullet-padding: 16px;
$swiper-bullet-padding-s: 20px;

// Navigation
$swiper-arrow-width: 48px;
$swiper-arrow-height: $swiper-arrow-width;
$swiper-arrow-padding: 0;
$swiper-arrow-width-m: 48px;

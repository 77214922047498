$products-slider-gutter-l: 32px;
$products-slider-gutter-s: 32px;
$products-slider-count-l: 2;
$products-slider-count-s: 1;

.NewsSlider {
    @include slider-layout(
        (
            lowest: (
                mode: "normal",
                gutter: $products-slider-gutter-s,
                count: $products-slider-count-s,
                full-screen: false,
            ),
            m: (
                gutter: $products-slider-gutter-l,
                count: $products-slider-count-l,
            ),
        )
    );

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__title {
        @extend %heading-medium;

        color: $color-gray;
        margin-right: auto;
    }

    &__slider {
        width: 100%;
    }

    html:root &__slider {
        margin-top: 32px;

        @include vw-medium-down {
            margin-top: 24px;
        }

        @include vw-small-down {
            margin-top: 16px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;

        &--count2 {
            @include vw-medium-up {
                display: none;
            }
        }
    }

    &__nav {
        display: flex;
        width: 104px;
        justify-content: space-between;
        margin-left: 24px;

        @include vw-small-down {
            display: none;
        }
    }

    :root &__navButton {
        @extend .Button, .Button--yellow;

        position: static;
        width: 48px;
        height: 48px;
        min-width: 48px;
        border-radius: 2px;
        border: none;
        padding: 0;

        svg {
            width: 8px;
            height: 12px;
        }
    }

}

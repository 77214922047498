.CategoriesList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px 40px;

    @include vw-medium-down {
        grid-template-columns: repeat(2, 1fr);
    }

    @include vw-xsmall-down {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
    }

    &__itemArticle {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include vw-xsmall-down {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    &__imageWrapper {
        width: 100%;

        @include vw-xsmall-down {
            max-width: 110px;
        }
    }

    &__itemPicture {
        @include aspect-ratio-picture;
        @include skeleton;
    }

    &__itemImage {
        @extend %full-size-image;
    }

    &__itemName {
        text-align: center;
        color: $color-gray;
        font-weight: $font-weight-medium;
        font-size: toRem(22px);
        max-width: 156px;

        @include vw-xsmall-down {
            max-width: 156px;
            margin-left: 16px;
            text-align: left;
        }

        @include vw-xxsmall-down {
            margin-left: 8px;
            font-size: toRem(20px);
        }
    }
}

$news-card-image-width: 584px;
$news-card-image-height: 320px;

.ArticleCard {
    $block: &;

    &__link {
        @media (hover: hover) {
            &:hover {
                #{$block}__picture::before {
                    opacity: 1;
                }

                #{$block}__title {
                    color: $color-yellow--dark;
                }
            }
        }

        &:focus {
            outline: none;
        }

        &:focus,
        &:active {
            #{$block}__picture::before {
                opacity: 1;
            }

            #{$block}__title {
                color: $color-yellow--dark;
            }
        }
    }

    &__picture {
        @include aspect-ratio-picture(584, 320);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color-yellow--dark, 0.3);
            transition: opacity 0.25s ease-in-out;
        }

        &--withImage {
            @include skeleton;
        }

        &.rss-news-card{
            padding-bottom: 0;
            max-height: 350px;
        }
    }


    &__image {
        @include image-with-scale;
    }

    &__placeholder {
        @extend %image-placeholder;
    }

    &__date,
    &__text {
        margin-top: 16px;

        @include vw-small-down {
            margin-top: 10px;
        }
    }

    &__date {
        display: block;
        font-size: toRem(16px);
        color: rgba($color-gray, 0.5);
        font-weight: $font-weight-medium;
    }

    &__title {
        @extend %heading-small;

        margin-top: 8px;
        transition: color 0.25s ease-in-out;
    }

    &__text {
        font-size: toRem(18px);
        color: $color-gray;

        @include vw-medium-down {
            font-size: toRem(16px);
        }

        @include vw-small-down {
            font-size: toRem(14px);
        }
    }
}

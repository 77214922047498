.AdvantagesSection {
    &__list {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 20px 80px;
        margin: 0 96px 48px;

        @include vw-large-down {
            grid-column-gap: 40px;
            margin-x: 64px;
        }

        @include vw-medium-down {
            grid-template-columns: repeat(2, auto);
            margin-x: 48px;
        }

        @include vw-small-down {
            grid-template-columns: 1fr;
            margin-x: 20px;
        }
    }

    &__image {
        padding-right: 96px;

        @include vw-large-down {
            padding-right: 64px;
        }

        @include vw-medium-down {
            padding-right: 48px;
        }

        @include vw-small-down {
            padding-right: 20px;
        }
    }
}

.AdvantageCard {
    &__title {
        display: inline-block;
        margin-bottom: 16px;
        color: $color-gray--darkest;
        border-bottom: 4px solid $color-yellow;
        font-weight: $font-weight-medium;
        font-size: toRem(32px);

        @include vw-small-down {
            font-size: toRem(24px);
        }
    }

    &__text {
        color: $color-black;
        font-size: toRem(18px);
    }
}

@import "swiper-settings";
@import "swiper-layout";

// stylelint-disable max-nesting-depth
.swiper {
    $block: &;

    &-container {
        &-with-scrollbar {
            @include vw-small-down {
                padding-bottom: 24px;
            }
        }
    }

    &-outer-container {
        position: relative;
    }

    :root &-slide {
        height: auto;
    }

    &-buttons {
        &-with-fraction {
            display: flex;
            width: 104px;
            justify-content: space-between;
            margin-left: 24px;

            @include vw-small-down {
                display: none;
            }
        }
    }

    &-button {
        $arrow: &;

        @include custom-schema("--swiper-arrow", $button-black-schema);

        align-items: center;
        appearance: none;
        size: $swiper-arrow-width;
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: none;
        padding: $swiper-arrow-padding;
        position: absolute;
        top: 50%;
        transition: 0.25s ease-in-out;
        transition-property: color, background-color, border-color, opacity, box-shadow;
        z-index: 1;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        border-color: var(--swiper-arrow-default-border);
        background-color: var(--swiper-arrow-default-background);
        color: var(--swiper-arrow-default-color);

        @include vw-medium-down {
            size: $swiper-arrow-width-m;
        }

        @media (hover: hover) {
            &:not(&-disabled) {
                &:hover {
                    background-color: var(--swiper-arrow-hover-background);
                    color: var(--swiper-arrow-hover-color);
                    border-color: var(--swiper-arrow-hover-border);
                }

                // Focus state for tab navigation
                &:focus-within,
                &:focus {
                    background-color: var(--swiper-arrow-focus-background);
                    color: var(--swiper-arrow-focus-color);
                    border-color: var(--swiper-arrow-focus-border);
                }
            }
        }

        // For mobiles and desktop
        &:not(&-disabled) {
            &:active {
                background-color: var(--swiper-arrow-active-background);
                color: var(--swiper-arrow-active-color);
                border-color: var(--swiper-arrow-active-border);
            }
        }

        &-prev {
            svg {
                margin-right: 1px;
            }
        }

        &-next {
            svg {
                margin-left: 3px;
            }
        }

        svg {
            width: 9px;
            height: 14px;
            stroke: currentColor;
        }

        :root &-disabled {
            background-color: var(--swiper-arrow-disabled-background);
            color: var(--swiper-arrow-disabled-color);
            border-color: var(--swiper-arrow-disabled-border);
            cursor: default;
            pointer-events: none;
        }

        &-static {
            position: static;
            border-radius: 2px;
            border: none;
        }
    }

    &-pagination {
        &-bullet {
            &-active {
                --swiper-pagination-color: #{$color-gray};
            }
        }
    }

    &-pagination-fraction {
        @extend %heading-medium;

        color: rgba($color-gray, 0.5);
        font-weight: $font-weight-regular;
    }

    &-pagination-current {
        color: $color-black;
        font-weight: $font-weight-medium;
    }
}
